import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link as RouterLink } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography, Link, GlobalStyles } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import SignUp from './SignUp';
import Unsubscribe from './Unsubscribe';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Disclaimers from './Disclaimers';
import StockChart from './StockChart';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d92d20',
    },
    background: {
      default: '#292929',  // Dark grey background
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

const App = () => {
  const currentYear = new Date().getFullYear();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={{
        body: { overflowX: 'hidden' },
        html: { overflowX: 'hidden' }
      }} />
      <Router>
        <Box
          display="flex"
          flexDirection="column"
          minHeight="100vh"
          sx={{
            backgroundColor: '#292929',  // Dark grey background
            color: '#fff',
            padding: '2rem 0', // Add padding on top and bottom
            maxWidth: '100%', // Ensure the container doesn't exceed viewport width
          }}
        >
          <Box flexGrow={1}>
            <Routes>
              <Route path="/" element={<SignUp />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/disclaimers" element={<Disclaimers />} />
              <Route path="/stocks/:ticker" element={<StockChart />} />
              <Route path="/stocks" element={<StockChart />} />
            </Routes>
          </Box>
          <Box component="footer" py={2} textAlign="center" sx={{ backgroundColor: '#292929', color: '#fff' }}>
            <Box display="flex" justifyContent="center" mb={1}>
              <Link href="https://x.com/pocket_quant" target="_blank" sx={{ color: '#fff', mx: 1 }}>
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </Link>
              <Link href="https://discord.gg/CMEh4rg3Px" target="_blank" sx={{ color: '#fff', mx: 1 }}>
                <FontAwesomeIcon icon={faDiscord} size="2x" />
              </Link>
              <Link href="mailto:business@bottleblue.biz" target="_blank" sx={{ color: '#fff', mx: 1 }}>
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </Link>
            </Box>
            <Typography variant="body2">
              Designed by <Link href="https://bottlebluellc.com" target="_blank" sx={{ color: '#d92d20', textDecoration: 'none' }}>Bottle Blue LLC</Link> © {currentYear}. All rights reserved.
            </Typography>
            <Typography variant="body2">
              Discord is a trademark of DISCORD INC. X and the X Logo are trademarks of X Corp.
            </Typography>
            <Typography variant="body2">
              pocketquant.io is not affiliated with any of these third parties.
            </Typography>
            <Box display="flex" justifyContent="center" mt={1}>
              <Link component={RouterLink} to="/privacy-policy" target="_blank" sx={{ color: '#d92d20', mx: 1 }}>
                Privacy Policy
              </Link>
              <Link component={RouterLink} to="/terms-of-service" target="_blank" sx={{ color: '#d92d20', mx: 1 }}>
                Terms of Service
              </Link>
              <Link component={RouterLink} to="/disclaimers" target="_blank" sx={{ color: '#d92d20', mx: 1 }}>
                Disclaimers
              </Link>
            </Box>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;
