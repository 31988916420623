import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, Grid, Paper, TextField, Button, Divider, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import StockInfoSection from './StockInfoSection'; // Adjust the path as necessary


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#1c1c1c',
  color: '#fff',
  borderRadius: '10px',
  boxShadow: 'none',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  minHeight: '600px',
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#2a2a2a',
  color: '#fff',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  border: '1px solid #333'
}));

const StockChart = () => {
  const { ticker: initialTicker } = useParams();
  const [ticker, setTicker] = useState(initialTicker || '');
  const [chartData, setChartData] = useState(null);
  const [relatedStocks, setRelatedStocks] = useState(null);
  const [stockInfo, setStockInfo] = useState(null);
  const [stockStats, setStockStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialTicker) {
      fetchChartData(initialTicker);
    }
  }, [initialTicker]);

  const fetchChartData = async (tickerSymbol) => {
    setLoading(true);
    setError('');
    // try {
    try {
      const response = await fetch(`https://8poesonquj.execute-api.us-east-1.amazonaws.com/prod/stocks/${tickerSymbol}`);
      const data = await response.json();
      if (response.ok) {
        setChartData({
          labels: data.dates,
          datasets: [
            {
              label: `Stock Price for ${data.ticker}`,
              data: data.prices,
              borderColor: '#00c853',
              backgroundColor: 'rgba(0, 200, 83, 0.1)',
              borderWidth: 2,
              tension: 0.4,
              pointRadius: 0,
            },
          ],
        });
        setStockInfo(data.info); // Set the additional stock info
        setStockStats(data.stats);
        setRelatedStocks(data.related);
      } else {
        setError(`Error: ${data}`);
      }
    } catch (err) {
      setError('Failed to fetch data');
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    setTicker(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (ticker) {
      fetchChartData(ticker);
    }
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => setShowFullDescription(!showFullDescription);

  const formatKey = (key) => {
    // Split the key at uppercase letters and underscores, then capitalize the first letter of each word
    return key
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, l => l.toUpperCase()) // Capitalize the first letter of each word
      .replace('Pe', 'PE') // Correct specific abbreviations
      .replace('Eps', 'EPS')
      .replace('Ceo', 'CEO')
      .replace('P E', 'P/E')
      .replace('Ebitda', 'EBITDA');
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <StyledContainer maxWidth="md">
        {initialTicker ? (
          <Typography variant="h4" gutterBottom align="center">
            {initialTicker.toUpperCase()}
          </Typography>
        ) : (
          <form onSubmit={handleFormSubmit} style={{ marginBottom: '20px', textAlign: 'center' }}>
            <TextField
              label="Enter Ticker"
              variant="outlined"
              value={ticker}
              onChange={handleInputChange}
              style={{ marginRight: '10px', backgroundColor: '#fff', borderRadius: '5px' }}
              inputProps={{ style: { color: '#000' } }}
            />
            <Button type="submit" variant="contained" style={{ backgroundColor: '#00c853', color: '#fff' }}>
              Get Data
            </Button>
          </form>
        )}

        {loading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {chartData ? (
          <>
            <Line data={chartData} options={{
              responsive: true,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Date',
                    color: '#fff',
                    font: {
                      family: 'Arial',
                      weight: 'bold',
                      size: 14,
                    },
                  },
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: '#fff',
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Price (USD)',
                    color: '#fff',
                    font: {
                      family: 'Arial',
                      weight: 'bold',
                      size: 14,
                    },
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                  ticks: {
                    color: '#fff',
                  },
                  beginAtZero: false,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  backgroundColor: '#1c1c1c',
                  titleColor: '#fff',
                  bodyColor: '#fff',
                  borderColor: '#00c853',
                  borderWidth: 1,
                },
              },
            }} />
            {stockInfo && (
              <Box mt={4} sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  About
                </Typography>
                <Divider sx={{ backgroundColor: '#fff' }} />
                {stockInfo.About && (
                  <Box display="flex" alignItems="center" sx={{ marginTop: 2, marginBottom: '25px' }}>
                    <Typography variant="body1" sx={{ flexGrow: 1, whiteSpace: 'pre-line' }}>
                      {showFullDescription ? stockInfo.About : `${stockInfo.About.substring(0, 300)}.`}
                      <Link href="#" onClick={toggleDescription} sx={{ marginLeft: 1, verticalAlign: 'baseline' }}>
                        {showFullDescription ? 'Show Less' : 'Show More'}
                      </Link>
                    </Typography>
                  </Box>
                )}
                <Grid container spacing={4} sx={{ marginBottom: '35px' }}>
                  {['Founded', 'Website', 'Headquarters', 'CEO', 'Employees', 'Ticker', 'Primary exchange'].map(key => (
                    <Grid item xs={3} key={key}>  {/* xs={3} ensures 4 items per row */}
                      <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                        {key.replace(/([A-Z])/g, ' $1').trim().replace('C E O', 'CEO')}
                      </Typography>
                      <Typography variant="body2">
                        {stockInfo[key]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>

                <Typography variant="h6" gutterBottom>
                  Key Statistics
                </Typography>
                <Divider sx={{ backgroundColor: '#fff' }} />
                {stockStats ? (
                  <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {Object.entries(stockStats).map(([key, value]) => (
                      <Grid item xs={6} sm={4} key={key}>  {/* xs={6} sm={4} ensures a responsive layout */}
                        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                          {formatKey(key)}  
                        </Typography>
                        <Typography variant="body2">
                          {typeof value === 'number' ? new Intl.NumberFormat().format(value) : value}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No statistics available.
                  </Typography>
                )}



{relatedStocks && (
  <Box sx={{ mt: 4, width: '100%' }}>
    <Typography variant="h6" gutterBottom>
      Related Stocks
    </Typography>
    <Divider sx={{ backgroundColor: '#fff', mb: 2.5 }} />  
    <Grid container spacing={2}>
      {relatedStocks.map((relatedStock, index) => (
        <Grid item xs={6} sm={4} md={3} key={index}>  
          <Paper sx={{ p: 1, backgroundColor: '#2a2a2a', color: '#fff', border: '1px solid #333', minHeight: '150px' }}>  
            <Typography variant="subtitle1" component="div">
              <Link href={`https://pocketquant.io/stocks/${relatedStock.ticker}`} sx={{ color: '#00c853', textDecoration: 'none' }}>
                {relatedStock.ticker}
              </Link>
            </Typography>
            <Typography variant="body2" component="div">
              {relatedStock.company_name}
            </Typography>
            <Typography variant="body2" component="div">
              Price: {relatedStock.price}
            </Typography>
            <Typography variant="body2" component="div" sx={{ color: relatedStock.change.startsWith('+') ? '#00c853' : '#d32f2f' }}>
              Change: {relatedStock.change}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Box>
)}

              </Box>
            )}

          </>
        ) : (
          !loading && <Typography>Enter a ticker to see the stock chart.</Typography>
        )}

      </StyledContainer>
    </Box>
  );
};

export default StockChart;