import React from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

const InfoSection = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1c1c1c',
  color: '#fff',
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
}));

const Header = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FactBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
}));

const StockInfoSection = ({ stockInfo }) => {
  return (
  <>
      <Header>
        <Typography variant="h5" component="h2" gutterBottom>
          About
        </Typography>
      </Header>
      <Typography variant="subtitle1" gutterBottom>
        {stockInfo.description}
    
      </Typography>
      </>

  );
};

export default StockInfoSection;
